<template>
    <div class="wrap">
        <!-- 面包屑 -->
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorder' }">{{$t('message.myorder')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorderinfo',query:{order_no:orderCode}}">{{$t('message.order')}}：{{orderCode}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{showFlag>1?$t('message.shinfo'):$t('message.shoinfo')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【订单信息】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap">
                <!-- 商品数据 -->
                <div class="shop">
                    <div class="title">
                        <!-- 售后商品 -->
                        <div class="label tit">{{$t('message.shshop')}}</div>
                        <div class="label rg" v-show="showFlag==1">
                            <span class="icon_checkbox" v-show="is_discount_falg" :class="checkedAll()" @click="getAllCheck"></span>
                            <span class="icon_checkbox dischecked" v-show="!is_discount_falg"></span>{{$t('message.allcheck')}}
                        </div>
                    </div>
                    <div class="shop-group" v-for="(item,index) in datalist" :key="index">
                        <div class="shop-check" v-show="showFlag==1">
                            <span class="icon_checkbox" v-show="item.is_discount==0" @click="getCheckBox(item)" :class="{'checked':item.checked}"></span>
                            <span class="icon_checkbox dischecked" v-show="item.is_discount!=0"></span>
                        </div>
                        <div class="shop-img"><img :src="item.goods_img" alt=""></div>
                        <div class="shop-info">
                            <div class="shop-title">
                                {{languageName=='cn'?item.cn_name:''}}
                                {{languageName=='en'?item.en_name:''}}
                                {{languageName=='it'?item.it_name:''}}
                            </div>
                            <div class="shop-numer">
                                {{languageName=='cn'?item.cn_spect_name:''}}
                                {{languageName=='en'?item.en_spect_name:''}}
                                {{languageName=='it'?item.it_spect_name:''}}
                                <span class="num">&times;{{item.goods_num}}</span></div>
                            <div class="shop-money">{{$t('message.m')}}{{item.price}}</div>
                        </div>
                    </div>
                    <div class="calcul">
                        <div class="calcul-group">
                            <div class="calcul-txt">{{$t('message.moenytotal')}}</div>
                            <div class="calcul-num">{{$t('message.m')}}{{orderAddObj.money}}</div>
                        </div>
                        <div class="calcul-group">
                            <div class="calcul-txt">{{$t('message.postmoneys')}}</div>
                            <div class="calcul-num">{{$t('message.m')}}
                            {{languageName=='cn'?postmoney.cn_postage:''}}
                            {{languageName=='en'?postmoney.en_postage:''}}
                            {{languageName=='it'?postmoney.it_postage:''}}
                            <span class="allnum">({{$t('message.fullpay')}}{{$t('message.m')}}{{amount}}{{$t('message.mpay')}}{{$t('message.freepay')}})</span></div>
                        </div>
                        <div class="calcul-group">
                            <div class="calcul-txt">AAMS</div>
                            <div class="calcul-num">{{$t('message.m')}}{{orderAddObj.totalaams}}</div>
                        </div>
                        <div class="calcul-group">
                            <div class="calcul-txt">IVA</div>
                            <div class="calcul-num">{{$t('message.m')}}{{orderAddObj.totaliva}}</div>
                        </div>
                    </div>
                    <div class="calcul-group brd">
                        <div class="calcul-txt">{{$t('message.ermovepost')}}</div>
                        <div class="red">-{{$t('message.m')}}
                            {{languageName=='cn'?postmoney.cn_postage:''}}
                            {{languageName=='en'?postmoney.en_postage:''}}
                            {{languageName=='it'?postmoney.it_postage:''}}
                        </div>
                    </div>
                    <div class="calcul-group" v-show="showFlag==1">
                        <div class="calcul-txt">{{$t('message.tmoney')}}</div>
                        <div class="calcul-nums" v-show="orderAddObj.is_no_postage==1">
                            {{$t('message.m')}}
                            {{languageName=='cn'?(discountmoney>0?(discountmoney-Number(postmoney.cn_postage).toFixed(2)):discountmoney):''}}
                            {{languageName=='en'?(discountmoney>0?(discountmoney-Number(postmoney.en_postage).toFixed(2)):discountmoney):''}}
                            {{languageName=='it'?(discountmoney>0?(discountmoney-Number(postmoney.it_postage).toFixed(2)):discountmoney):''}}
                        </div>
                        <div class="calcul-nums" v-show="orderAddObj.is_no_postage==0">
                            {{$t('message.m')}}{{orderAddObj.money}}
                        </div>
                    </div>
                    <!-- 退款金额 -->
                    <div class="calcul-group" v-show="showFlag!=1">
                        <div class="calcul-txt">{{$t('message.tmoney')}}</div>
                        <div class="calcul-nums" v-show="orderAddObj.is_no_postage==1">
                            {{$t('message.m')}}
                            {{languageName=='cn'?(Number(orderAddObj.totalmoney)-Number(postmoney.cn_postage)).toFixed(2):''}}
                            {{languageName=='en'?(Number(orderAddObj.totalmoney)-Number(postmoney.en_postage)).toFixed(2):''}}
                            {{languageName=='it'?(Number(orderAddObj.totalmoney)-Number(postmoney.it_postage)).toFixed(2):''}}
                        </div>
                        <div class="calcul-nums" v-show="orderAddObj.is_no_postage==0">
                            {{$t('message.m')}}{{orderAddObj.money}}
                        </div>
                    </div>
                </div>
                <!-- 售后详情和申请售后 -->
                <div class="pc-all">
                    <!-- 申请售后 -->
                    <div class="" v-show="showFlag==1">
                        <div class="pc-title">{{$t('message.shoinfo')}}</div>
                        <div class="pc-select">
                            <div class="pc-select-namediy" @click="moneyTypeShow=!moneyTypeShow">{{moneyTypeName?moneyTypeName:$t('message.tkwhy')}}</div>
                            <div class="pc-select-diy" v-show="moneyTypeShow">
                                <div class="diylist" v-for="(item,index) in moneyTypeList" :key="index" @click="getType(item)">
                                    {{languageName=='cn'?item.cn_name:''}}
                                    {{languageName=='en'?item.en_name:''}}
                                    {{languageName=='it'?item.it_name:''}}
                                </div>
                            </div>
                            <span class="el-icon-arrow-down"></span>
                        </div>
                        <div class="pc-input_odd">
                            <div class="tit">{{$t('message.tmoney')}}</div>
                            <div class="money" v-show="orderAddObj.is_no_postage==1">
                                {{$t('message.m')}}
                                {{languageName=='cn'?(discountmoney>0?(discountmoney-Number(postmoney.cn_postage).toFixed(2)):discountmoney):''}}
                                {{languageName=='en'?(discountmoney>0?(discountmoney-Number(postmoney.en_postage).toFixed(2)):discountmoney):''}}
                                {{languageName=='it'?(discountmoney>0?(discountmoney-Number(postmoney.it_postage).toFixed(2)):discountmoney):''}}
                            </div>
                            <div class="money" v-show="orderAddObj.is_no_postage==0">
                                {{$t('message.m')}}{{orderAddObj.money}}
                            </div>
                        </div>
                        <div class="pc-input">
                            <el-input type="textarea" v-model="content" :placeholder="$t('message.tkbz')"></el-input>
                        </div>
                        <div class="pc-link">
                            <span class="pc-linkbtn" @click="getSubmit">{{$t('message.subforms')}}</span>
                        </div>
                    </div>
                    <!-- 售后详情 -->
                    <div class="pc-order-info-state" v-show="showFlag==2">
                        <div class="pc-title-state">
                            <div class="pc-state-name">
                                <span class="pc-orderstate"></span>{{$t('message.state0')}}：
                            </div>
                            <div class="pc-state-txt">
                                {{orderAddObj.after_sale==1?$t('message.showstate1'):''}}
                                {{orderAddObj.after_sale==2?$t('message.showstate2'):''}}
                                {{orderAddObj.after_sale==3?$t('message.showstate3'):''}}
                                {{orderAddObj.after_sale==4?$t('message.showstate4'):''}}
                                {{orderAddObj.after_sale==5?$t('message.showstate5'):''}}
                                {{orderAddObj.after_sale==6?$t('message.showstate6'):''}}
                                {{orderAddObj.after_sale==7?$t('message.showstate7'):''}}
                            </div>
                        </div>
                        <div class="pc-pay-state"><span class="pc-label">{{$t('message.tkwhy')}}：</span><span class="pc-leg">
                            {{languageName=='cn'?objType.cn_name:''}}
                            {{languageName=='en'?objType.en_name:''}}
                            {{languageName=='it'?objType.it_name:''}}
                            </span></div>
                        <div class="pc-pay-state"><span class="pc-label">{{$t('message.tmoney')}}：</span><span class="pc-leg">{{$t('message.m')}}{{showObj&&showObj.money}}</span></div>
                        <div class="pc-pay-state"><span class="pc-label">{{$t('message.tkbz')}}：</span><span class="pc-leg">{{showObj&&showObj.season}}</span></div>
                        <div class="pc-pay-state" v-show="showObj&&showObj.create_time"><span class="pc-label">{{$t('message.palytime')}}：</span><span class="pc-leg">{{showObj&&showObj.create_time}}</span></div>
                        <div class="pc-pay-state">·{{$t('message.writstate2')}}~</div>
                    </div>
                    <!-- 售后详情 -->
                    <div class="pc-order-info-state" v-show="showFlag==3">
                        <div class="pc-title-state">
                            <div class="pc-state-name">
                                <span class="pc-orderstate"></span>{{$t('message.state0')}}：
                            </div>
                            <div class="pc-state-txt">
                                {{orderAddObj.after_sale==1?$t('message.showstate1'):''}}
                                {{orderAddObj.after_sale==2?$t('message.showstate2'):''}}
                                {{orderAddObj.after_sale==3?$t('message.showstate3'):''}}
                                {{orderAddObj.after_sale==4?$t('message.showstate4'):''}}
                                {{orderAddObj.after_sale==5?$t('message.showstate5'):''}}
                                {{orderAddObj.after_sale==6?$t('message.showstate6'):''}}
                                {{orderAddObj.after_sale==7?$t('message.showstate7'):''}}
                            </div>
                        </div>
                        <div class="pc-pay-state"><span class="pc-label">{{$t('message.tkwhy')}}：</span><span class="pc-leg">
                            {{languageName=='cn'?objType.cn_name:''}}
                            {{languageName=='en'?objType.en_name:''}}
                            {{languageName=='it'?objType.it_name:''}}
                        </span></div>
                        <div class="pc-pay-state"><span class="pc-label">{{$t('message.tmoney')}}：</span><span class="pc-leg">{{$t('message.m')}}{{showObj&&showObj.money}}</span></div>
                        <div class="pc-pay-state"><span class="pc-label">{{$t('message.tkbz')}}：</span><span class="pc-leg">{{showObj&&showObj.season}}</span></div>
                        <div class="pc-pay-state" v-show="showObj&&showObj.create_time"><span class="pc-label">{{$t('message.palytime')}}：</span><span class="pc-leg">{{showObj&&showObj.create_time}}</span></div>
                        <div class="pc-pay-state" v-show="showObj&&showObj.complete_time"><span class="pc-label">{{$t('message.fintime')}}：</span><span class="pc-leg">{{showObj&&showObj.complete_time}}</span></div>
                        <div class="pc-pay-state">·{{$t('message.writstate2')}}~</div>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- wap布局【订单信息】 -->
        <b-container class="wap-shopcar">
            <div class="wap-shopinfo">
                <div class="wap-title">
                    <div class="wap-label tit">{{$t('message.shshop')}}</div>
                    <div class="wap-label rg" v-show="showFlag==1"><span class="icon_checkbox" :class="{'checked':checked}" @click="getAllCheck"></span>{{$t('message.allcheck')}}</div>
                </div>
                <div class="wap-shop-group" v-for="(item,index) in datalist" :key="index">
                    <div class="wap-shop-check" v-show="showFlag==1"><span class="icon_checkbox"  @click="item.checked=!item.checked" :class="{'checked':item.checked}"></span></div>
                    <div class="wap-shop-img"><img :src="item.goods_img" alt=""></div>
                    <div class="wap-shop-info">
                        <div class="wap-shop-title">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                        </div>
                        <div class="wap-shop-numer">
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}    
                        </div>
                        <div class="wap-shop-money">{{$t('message.m')}}{{item.price}}</div>
                    </div>
                    <span class="wap-num">&times;{{item.goods_num}}</span>
                </div>
                <div class="wap-calcul">
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">{{$t('message.moenytotal')}}</div>
                        <div class="wap-calcul-num">{{$t('message.m')}}{{orderAddObj.money}}</div>
                    </div>
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">{{$t('message.postmoneys')}}</div>
                        <div class="wap-calcul-num">{{$t('message.m')}}
                            {{languageName=='cn'?postmoney.cn_postage:''}}
                            {{languageName=='en'?postmoney.en_postage:''}}
                            {{languageName=='it'?postmoney.it_postage:''}}
                            <span class="wap-allnum">({{$t('message.fullpay')}}{{$t('message.m')}}{{amount}}{{$t('message.mpay')}}{{$t('message.freepay')}})</span></div>
                    </div>
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">AAMS</div>
                        <div class="wap-calcul-num">{{$t('message.m')}}{{orderAddObj.totalaams}}</div>
                    </div>
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">IVA</div>
                        <div class="wap-calcul-num">{{$t('message.m')}}{{orderAddObj.totaliva}}</div>
                    </div>
                </div>
                <div class="wap-calcul-group wap-brd">
                    <div class="wap-calcul-txt">{{$t('message.ermovepost')}}</div>
                    <div class="wap-red">-{{$t('message.m')}}
                        {{languageName=='cn'?postmoney.cn_postage:''}}
                        {{languageName=='en'?postmoney.en_postage:''}}
                        {{languageName=='it'?postmoney.it_postage:''}}
                    </div>
                </div>
                <div class="wap-calcul-group" v-show="showFlag==1">
                    <div class="wap-calcul-txt">{{$t('message.tmoney')}}</div>
                    <div class="wap-calcul-nums" v-show="orderAddObj.is_no_postage==1">
                        {{$t('message.m')}}
                        {{languageName=='cn'?(discountmoney>0?(discountmoney-Number(postmoney.cn_postage).toFixed(2)):discountmoney):''}}
                        {{languageName=='en'?(discountmoney>0?(discountmoney-Number(postmoney.en_postage).toFixed(2)):discountmoney):''}}
                        {{languageName=='it'?(discountmoney>0?(discountmoney-Number(postmoney.it_postage).toFixed(2)):discountmoney):''}}
                    </div>
                    <div class="wap-calcul-nums" v-show="orderAddObj.is_no_postage==0">
                        {{$t('message.m')}}{{orderAddObj.money}}
                    </div>
                </div>
                <div class="wap-calcul-group" v-show="showFlag!=1">
                    <div class="wap-calcul-txt">{{$t('message.tmoney')}}</div>
                    <div class="wap-calcul-nums" v-show="orderAddObj.is_no_postage==1">
                        {{$t('message.m')}}
                        {{languageName=='cn'?(Number(orderAddObj.totalmoney)-Number(postmoney.cn_postage)).toFixed(2):''}}
                        {{languageName=='en'?(Number(orderAddObj.totalmoney)-Number(postmoney.en_postage)).toFixed(2):''}}
                        {{languageName=='it'?(Number(orderAddObj.totalmoney)-Number(postmoney.it_postage)).toFixed(2):''}}
                    </div>
                    <div class="wap-calcul-nums" v-show="orderAddObj.is_no_postage==0">
                        {{$t('message.m')}}{{orderAddObj.money}}
                    </div>
                </div>
            </div>
            <div class="wap-shop-state" v-show="showFlag==1">
                <div class="wap-title">{{$t('message.shoinfo')}}</div>
                <div class="wap-select">
                    <div class="pc-select-namediy" @click="moneyTypeShow=!moneyTypeShow">{{moneyTypeName?moneyTypeName:$t('message.tkwhy')}}</div>
                    <div class="pc-select-diy" v-show="moneyTypeShow">
                        <div class="diylist" v-for="(item,index) in moneyTypeList" :key="index" @click="getType(item)">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}
                        </div>
                    </div>
                    <span class="el-icon-arrow-down"></span>
                </div>
                <div class="wap-input_odd">
                    <div class="wap-tit">{{$t('message.tmoney')}}</div>
                    <div class="wap-money" v-show="orderAddObj.is_no_postage==1">
                        {{$t('message.m')}}
                        {{languageName=='cn'?(discountmoney>0?(discountmoney-Number(postmoney.cn_postage).toFixed(2)):discountmoney):''}}
                        {{languageName=='en'?(discountmoney>0?(discountmoney-Number(postmoney.en_postage).toFixed(2)):discountmoney):''}}
                        {{languageName=='it'?(discountmoney>0?(discountmoney-Number(postmoney.it_postage).toFixed(2)):discountmoney):''}}
                    </div>
                    <div class="wap-money" v-show="orderAddObj.is_no_postage==0">
                        {{$t('message.m')}}{{orderAddObj.money}}
                    </div>
                </div>
                <div class="wap-input">
                    <el-input type="textarea" v-model="content" :placeholder="$t('message.tkbz')"></el-input>
                </div>
                <div class="wap-link">
                    <span class="wap-linkbtn" @click="getSubmit">{{$t('message.subforms')}}</span>
                </div>
            </div>
            <b-row class="wap-shop-state" v-show="showFlag==2">
                <div class="wap-title-state">
                    <div class="wap-state-name">
                        <span class="wap-orderstate"></span>{{$t('message.state0')}}：
                    </div>
                    <div class="wap-state-txt">
                        {{orderAddObj.after_sale==1?$t('message.showstate1'):''}}
                        {{orderAddObj.after_sale==2?$t('message.showstate2'):''}}
                        {{orderAddObj.after_sale==3?$t('message.showstate3'):''}}
                        {{orderAddObj.after_sale==4?$t('message.showstate4'):''}}
                        {{orderAddObj.after_sale==5?$t('message.showstate5'):''}}
                        {{orderAddObj.after_sale==6?$t('message.showstate6'):''}}
                        {{orderAddObj.after_sale==7?$t('message.showstate7'):''}}
                    </div>
                </div>
                <div class="wap-pay-state wap-marg"><span class="wap-label">{{$t('message.tkwhy')}}：</span><span class="wap-leg">
                    {{languageName=='cn'?objType.cn_name:''}}
                    {{languageName=='en'?objType.en_name:''}}
                    {{languageName=='it'?objType.it_name:''}}
                </span></div>
                <div class="wap-pay-state wap-marg"><span class="wap-label">{{$t('message.tmoney')}}：</span><span class="wap-leg">{{$t('message.m')}}{{showObj&&showObj.money}}</span></div>
                <div class="wap-pay-state wap-marg"><span class="wap-label">{{$t('message.tkbz')}}：</span><span class="wap-leg">{{showObj&&showObj.season}}</span></div>
                <div class="wap-pay-state wap-marg" v-show="showObj&&showObj.create_time"><span class="wap-label">{{$t('message.palytime')}}：</span><span class="wap-leg">{{showObj&&showObj.create_time}}</span></div>
                <div class="wap-pay-state"><span class="wap-txt">·{{$t('message.writstate2')}}~</span></div>
            </b-row>
            <b-row class="wap-shop-state" v-show="showFlag==3">
                <div class="wap-title-state">
                    <div class="wap-state-name">
                        <span class="wap-orderstate"></span>{{$t('message.state0')}}：
                    </div>
                    <div class="wap-state-txt">
                        {{orderAddObj.after_sale==1?$t('message.showstate1'):''}}
                        {{orderAddObj.after_sale==2?$t('message.showstate2'):''}}
                        {{orderAddObj.after_sale==3?$t('message.showstate3'):''}}
                        {{orderAddObj.after_sale==4?$t('message.showstate4'):''}}
                        {{orderAddObj.after_sale==5?$t('message.showstate5'):''}}
                        {{orderAddObj.after_sale==6?$t('message.showstate6'):''}}
                        {{orderAddObj.after_sale==7?$t('message.showstate7'):''}}
                    </div>
                </div>
                <div class="wap-pay-state wap-marg"><span class="wap-label">{{$t('message.tkwhy')}}：</span><span class="wap-leg">
                    {{languageName=='cn'?objType.cn_name:''}}
                    {{languageName=='en'?objType.en_name:''}}
                    {{languageName=='it'?objType.it_name:''}}
                </span></div>
                <div class="wap-pay-state wap-marg"><span class="wap-label">{{$t('message.tmoney')}}：</span><span class="wap-leg">{{$t('message.m')}}{{showObj&&showObj.money}}</span></div>
                <div class="wap-pay-state wap-marg"><span class="wap-label">{{$t('message.tkbz')}}：</span><span class="wap-leg">{{showObj&&showObj.season}}</span></div>
                <div class="wap-pay-state wap-marg" v-show="showObj&&showObj.create_time"><span class="wap-label">{{$t('message.palytime')}}：</span><span class="wap-leg">{{showObj&&showObj.create_time}}</span></div>
                <div class="wap-pay-state wap-marg" v-show="showObj&&showObj.complete_time"><span class="wap-label">{{$t('message.fintime')}}：</span><span class="wap-leg">{{showObj&&showObj.complete_time}}</span></div>
                <div class="wap-pay-state"><span class="wap-txt">·{{$t('message.writstate2')}}~</span></div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'myorderinfo',
    components:{
    },
    data(){
        return{
            languageName:'',
            languageObj:{},
            objType:{},
            showObj:{},
            postmoney:{},//邮费
            moneyTypeName:'',
            moneyTypeShow:false,
            checked:false,
            showFlag:1,
            amount:'',
            content:'',
            orderCode:'',
            orderAddObj:{},
            datalist:[],
            is_discount_falg:false,
            postMoney:5,
            allMoney:0,
            token:'',
            moneyTypeList:[],
            discountmoney:0,
        }
    },
    watch:{
        
    },
    computed:{
       
    },
    methods: {
        getCheckBox(item){
            if(item){
                item.checked=!item.checked;
                let money = 0;
                this.datalist.map((v)=>{
                    if(v.checked){
                        money+=Number(v.totalprice);
                    }
                });
                this.discountmoney = money;
            }
        },
        checkedAll() {
            var selectedNum = 0;
            var totalLength=0;
            this.datalist.map((val) =>{
                totalLength = this.datalist.length;
                if(val.checked) {
                    selectedNum+=1;
                }
            });
            if(selectedNum==totalLength) {
                return 'checked';
            } else if(selectedNum>0) {
                return 'halfChecked'
            } else {
                return 'blank';
            }
        },
        // 全选按钮
        getAllCheck(){
            let money = 0;
            var selectedNum = 0;
            var totalLength=this.datalist.length;
            this.datalist.map((val)=>{
                if(val.checked) {
                    selectedNum+=1;
                }
            });
            if(selectedNum==totalLength) {
                this.datalist.map((val)=>{
                    val.checked = false;
                });
            } else {
                this.datalist.map((val)=>{
                    val.checked = true;
                });
            }
            this.datalist.map((val)=>{
                if(val.checked&&val.is_discount==0) {
                    money+=Number(val.totalprice);
                }
            });
            this.discountmoney = money;
        },
        // 选择退款原因
        getType(item){
            if(item){
                this.objType = item;
                if(this.languageName=='cn'){
                    this.moneyTypeName = item.cn_name;
                }else if(this.languageName=='en'){
                    this.moneyTypeName = item.en_name;
                }else if(this.languageName=='it'){
                    this.moneyTypeName = item.it_name;
                }
                this.moneyTypeShow = !this.moneyTypeShow;
            }
        },
        // 获取国家包邮金额范围
        getPackAmount(){
            this.$http.api_order_getpackagemailamount({
                country:this.languageName//国家编码
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.amount = res.data.amount;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 获取系统保底邮费
        getQueryPostage(){
            this.$http.api_order_getpostage().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.postmoney = res.data;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 获取订单详情
        getOrderInfo(token,order_no){
            this.$http.api_order_getorderinfo({
                token:token,
                orderno:order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            let aamsstr_arr = []
                            let ivastr_arr = []
                            this.orderAddObj = res.data;
                            if(res.data.totalaams){
                                aamsstr_arr = res.data.totalaams.split('.')
                                this.orderAddObj.totalaams = aamsstr_arr[0]+'.'+aamsstr_arr[1].slice(0,2)
                            }
                            if(res.data.totaliva){
                                ivastr_arr = res.data.totaliva.split('.')
                                this.orderAddObj.totaliva = ivastr_arr[0]+'.'+ivastr_arr[1].slice(0,2)
                            }
                            res.data.goods.map((v)=>{
                                v.checked = false;
                                if(v.is_discount==0){
                                    this.is_discount_falg = true;
                                }
                            });
                            this.datalist = res.data.goods;
                            this.orderAddObj = res.data;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 获取售后详情
        getOrderShowInfo(token,order_no){
            this.$http.api_order_getaftersaleinfo({
                token:token,
                orderno:order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.showObj = res.data;
                            /*{
                                "id": 8,
                                "user_id": 34,
                                "order_id": 241,
                                "order_status": 4,
                                "season": "不喜欢",
                                "money": "89.99",
                                "create_time": "2022-07-07 15:47:56",
                                "complete_time": null,
                                "status": 4,
                                "ogids": "279,278,277",
                                "decline_reason": null,
                                "fail_reason": null,
                                "type_id": 1,
                                "status_str": "拒绝",
                                "type_info": {
                                    "id": 1,
                                    "cn_name": "不喜欢",
                                    "en_name": "dislike",
                                    "it_name": "antipatia",
                                    "sort": 2,
                                    "create_time": "2022-06-27 15:40:16"
                                }
                            }*/ 
                            this.moneyTypeList.map((v)=>{
                                if(v.id==res.data.type_id){
                                    this.objType = v;
                                }
                            })
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        // return false;
                    }
                }
            });
        },
        // 获取退款类型数据列表
        getMoneyType(){
            this.$http.api_order_getaftersaletypelist().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.moneyTypeList = res.data;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 提交售后申请
        getSubmit(){
            let arr = []
            this.datalist.map((v)=>{
                if(v.checked){
                    arr.push(v.og_id);
                }
            });
            if(arr.length<1){
                ElementUI.Message({
                    message:this.languageObj.tkshoptip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(!this.objType.id){
                ElementUI.Message({
                    message:this.languageObj.searontip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.content==''){
                ElementUI.Message({
                    message:this.languageObj.tkdetailtip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else{
                let money = 0;
                if(this.orderAddObj.is_no_postage==1){
                    if(this.languageName=='cn'){
                        money = (Number(this.orderAddObj.totalmoney)-Number(this.postmoney.cn_postage)).toFixed(2)
                    }else if(this.languageName=='en'){
                        money = (Number(this.orderAddObj.totalmoney)-Number(this.postmoney.en_postage)).toFixed(2)
                    }else if(this.languageName=='it'){
                        money = (Number(this.orderAddObj.totalmoney)-Number(this.postmoney.it_postage)).toFixed(2)
                    }
                }else{
                    money = this.orderAddObj.money
                }
                let param = {
                    token:this.token,// 	用户token
                    orderno:this.orderCode,// 	用户订单号
                    typeid:this.objType.id,// 	退款原因
                    season:this.content,// 	退款说明
                    ogids:arr,// 	订单商品id
                    money:money,// 	退款金额 用于数据校验
                }
                this.$http.api_order_applyrefund(param).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                ElementUI.Message({
                                    message:this.languageObj.applitip,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                                this.showFlag = 2;
                                this.getOrderShowInfo(this.token,this.orderCode);//获取售后详情
                                this.getOrderInfo(this.token,this.orderCode);//获取订单详情
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        }
    },
    created(){
        // 当订单数据大于零时，计算所有商品的总金额
        // if(this.datalist.length>0){
        //     this.datalist.map((v)=>{
        //         v.shopCal = parseInt(v.money)*v.number;
        //     });
        //     var money = 0;
        //     for(var i=0;i<this.datalist.length;i++){
        //         money += this.datalist[i].shopCal;
        //     }
        //     this.allMoney = money;
        // }
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "tkshoptip":"请选择退款商品",
                "searontip":"请选择退款原因",
                "tkdetailtip":"请输入退款说明",
                "applitip":"申请中"
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "tkshoptip":"Please select a refund item",
                "searontip":"Please select a refund reason",
                "tkdetailtip":"Please enter refund instructions",
                "applitip":"In application"
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "tkshoptip":"Seleziona un articolo per il rimborso",
                "searontip":"Seleziona un motivo per il rimborso",
                "tkdetailtip":"Inserisci il metodo di rimborso per il rimborso",
                "applitip":"In applicazione"
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            let routes = this.$route.query;//地址栏参数
            if(routes){
                this.orderCode = routes.order_no;
                let type = routes.type;
                if(type){
                    this.showFlag = parseInt(type);
                }
                this.getOrderInfo(token,routes.order_no);//获取订单详情
                this.getPackAmount();//获取国家包邮金额范围
                this.getMoneyType();//获取退款类型数据列表
                this.getQueryPostage();//获取系统保底邮费
                this.getOrderShowInfo(token,routes.order_no);//获取售后详情
            }
        }else{
            // ElementUI.Message({
            //     message:$t('message.logintip'),
            //     type:'error',
            //     center: true,
            //     offset:10,
            // });
            // this.$store.commit('setLoginStatus',true);
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.navtab{
    padding:20px 10px 10px;
    
}
.shopintab{
    padding:0;
}
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        .shop{
            width: 40%;
            background: #f4f7fe;
            padding:20px 20px 40px;
            .title{
                padding:10px 0;
                display: flex;
                -webkit-box-orient: horizontal;
                border-bottom:1px solid #dde0ed;
                .label{
                    display: flex;
                    width: 50%;
                    .icon_checkbox{
                        float: left;
                        display: block;
                        width:20px;
                        height:20px;
                        background:url(~@/assets/images/icon_check.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-top:3px;
                        margin-right:8px;
                        cursor: pointer;
                        border-radius: 3px;
                    }
                    .dischecked{
                        background:url(~@/assets/images/icon_duoxuan_lapse.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .checked{
                        background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
                .tit{
                    font-size:18px;
                }
                .rg{
                    color: #95959e;
                    justify-content: flex-end;
                }
            }
            .shop-group{
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                padding:10px 0;
                .shop-check{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding:0 10px;
                    .icon_checkbox{
                        float: left;
                        display: block;
                        width:20px;
                        height:20px;
                        background:url(~@/assets/images/icon_check.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-top:3px;
                        cursor: pointer;
                        border-radius: 3px;
                    }
                    .dischecked{
                        background:url(~@/assets/images/icon_duoxuan_lapse.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .checked{
                        background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
                .shop-img{
                    width:25%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .shop-info{
                    width:75%;
                    padding-left:20px;
                    .shop-title{
                        
                        line-height: 30px;
                    }
                    .shop-numer{
                        color: #a4a4ad;
                        .num{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                    .shop-money{
                        margin-top:20px;
                        color: #bf0000;
                    }
                }
            }
            .calcul{
                padding:20px 0px;
                border-top: 1px solid #dde0ed;
                border-bottom: 1px solid #dde0ed;
                .calcul-group{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:5px 0;
                    .calcul-txt{
                        width: 50%;
                        
                    }
                    .calcul-num{
                        width: 50%;
                        text-align: right;
                        
                        .allnum{
                            color: #9c9da5;
                            margin-left: 4px;
                        }
                    }
                }
            }
            
            .brd{
                border-bottom: 1px solid #dde0ed;
            }
            .calcul-group{
                display: flex;
                -webkit-box-orient: horizontal;
                padding:15px 0px;
                .calcul-txt{
                    width: 50%;
                    
                }
                .calcul-nums{
                    width: 50%;
                    text-align: right;
                    font-size:16px;
                    
                }
                .red{
                    width: 50%;
                    text-align: right;
                    font-size:16px;
                    color:#bf0000;
                }
            }
        }
        .pc-all{
            width: 60%;
            padding:0px 60px 40px;
            .pc-title{
                font-size:18px;
                padding-bottom: 10px;
            }
            .pc-select{
                width: 100%;
                height:58px;
                margin-bottom: 20px;
                padding:0 20px;
                border-radius:5px;
                border:1px solid #DDE0ED;
                position: relative;
                cursor: pointer;
                background: #fff;
                .pc-select-namediy{
                    position: absolute;
                    left:0;
                    top:0;
                    height:58px;
                    line-height:58px;
                    width: 100%;
                    padding:0 20px;
                }
                .pc-select-diy{
                    position: absolute;
                    left:0;
                    top:56px;
                    z-index:1;
                    min-height:100px;
                    width: 100%;
                    border-radius:5px;
                    border:1px solid #DDE0ED;
                    width: 100%;
                    background: #fff;
                    .diylist{
                        padding:10px 20px;
                        background: #fff;
                        border-bottom:1px solid #DDE0ED;
                        &:last-child{
                           border-bottom:none; 
                        }
                    }
                }
                .pc-select{
                    display: flex;
                    height: auto;
                    width: 100%;
                    border: none;
                    outline: none;
                    padding: 0;
                    margin: 0 0px;
                    background: transparent;
                    appearance:none;   
                    -moz-appearance:none;   
                    -webkit-appearance:none; 
                }
                .pc-one_select{
                    height:58px;
                    line-height:58px;
                    width: 100%;
                    border: none;
                    outline: none;
                    background: transparent;
                    appearance:none;   
                    -moz-appearance:none;   
                    -webkit-appearance:none; 
                }
                .el-icon-arrow-down{
                    position: absolute;
                    right: 20px;
                    top:20px;
                }
                .pc-select-tit{
                    width: 100%;
                    height:28px;
                    line-height:28px;
                    
                    color: #95959E;
                }
                /deep/.el-select{
                    width: 100%;
                }
            }
            .pc-input_odd{
                margin-bottom: 20px;
                padding:5px 15px;
                border-radius:4px;
                border: 1px solid #dde0ed;
                .tit{
                    color: #9d9da5;
                }
                .money{
                   color: #1f73b7
                }
            }
            .pc-input{
                /deep/.el-textarea__inner{
                    height:130px;
                    resize: none;
                    font-size:16px;
                    background: transparent;
                    border: 1px solid #dde0ed;
                }
            }
            .pc-link{
                height: 40px;
                width: 100%;
                text-align: right;
                margin-top: 20px;
                .pc-linkbtn{
                    display: inline-block;
                    padding:10px 20px;
                    border-radius:4px;
                    background: #222;
                    color: #fff;
                    
                    cursor: pointer;
                }
            }
            .pc-order-info-state{
                padding-top:70px;
                .pc-title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .pc-state-name{
                        font-size:18px;
                        .pc-orderstate{
                            display: inline-block;
                            width: 36px;
                            height:36px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 20px;
                        }
                    }
                    .pc-state-txt{
                        font-size:18px;
                        vertical-align: middle;
                        padding-top:3px;
                        .pc-title{
                            font-size:18px;
                        }
                    }
                }
                .pc-pay-state{
                    padding-left: 60px;
                    
                    color: #616166;
                    .pc-label{
                        display: inline-block;
                        width: 80px;
                        
                        color: #616166;
                    }
                    .pc-paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                    .pc-ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 20px;
                        
                    }
                    .pc-leg{
                        display: inline-block;
                        padding:5px 20px;
                        
                        color: #2e2e2e;
                    }

                }
                .pc-marg{
                    margin-top: 40px;
                }
            }
        }
    }
}

.order-header{
    padding:7px 0;
    background: #f4f7fe;
    display: flex;
    -webkit-box-orient: horizontal;
    .head-th{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #616166;
        padding:3px 0;
        
    }
    .col1{
        width:49%;
        border-right: 1px solid #dde0ed;
    }
    .col2{
        width:10.5%;
        border-right: 1px solid #dde0ed;
    }
    .col3{
        width:10.5%;
        border-right: 1px solid #dde0ed;
    }
    .col4{
        width:15%;
        border-right: 1px solid #dde0ed;
    }
    .col5{
        width:15%;
    }
}
.order-group{
    width: 100%;
    padding:0px 0 0px;
    .order-item{
        background: #f4f7fe;
        .order-wrap{
            border: 1px solid #dde0ed;
            display: flex;
            width: 100%;
            -webkit-box-orient: horizontal;
            background: #fff;
            .order-shop{
                width:70%;
                border-right:1px solid #dde0ed;
                .order-shop-item{
                    display: flex;
                    width: 100%;
                    -webkit-box-orient: horizontal;
                    border-top:1px solid #dde0ed;
                    &:first-child{
                        border-top: none;
                    }
                    .order-shop-img{
                        width:70%;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding: 20px 0;
                        border-right:1px solid #dde0ed;
                        .shop-img{
                            width:30%;
                            img{
                                width:80%;
                                height:100px;
                            }
                        }
                        .shop-title{
                            width:70%;
                            font-size:16px;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .order-shop-money{
                        width:15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-right:1px solid #dde0ed;
                        color: #7d7d80;
                        
                    }
                    .order-shop-number{
                        width:15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        text-align: center;
                        color: #7d7d80;
                    }
                }
            }
            .order-money{
                width:15%;
                display: flex;
                -webkit-box-orient: vertical;
                align-items: center;
                justify-content: center;
                border-right:1px solid #dde0ed;
                .cen{
                    .money{
                        font-weight: bold;
                        
                    }
                    .paytype{
                        padding:2px 0;
                        .red{
                            color:#bf0000;

                        }
                    }
                    .post{
                        
                        color:#95959e;
                    }
                }
            }
            .order-more{
                width:15%;
                display: flex;
                -webkit-box-orient: vertical;
                align-items: center;
                justify-content: center;
                .cen{
                    .paybtn{
                        cursor: pointer;
                        color:#fff;
                        background: #222;
                        padding:5px 20px;
                        
                        margin:3px 0;
                    }
                    .info-order{
                        cursor: pointer;
                        color: #616166;
                        text-align: center;
                        padding:3px 0;
                    }
                }
            }
        }
        .order-foot{
            padding:20px 10px 20px;
            color:#616166;
            .order-foot-shopmoney{
                
                text-align: right;
                span{
                    display: inline-block;
                    text-align: right;
                    width:150px;
                    
                }
            }
            .order-foot-post{
                
                text-align: right;
                padding:5px 0;
                span{
                    display: inline-block;
                    text-align: right;
                    width:150px;
                    
                }
            }
            .order-foot-money{
                
                text-align: right;
                span{
                    display: inline-block;
                    text-align: right;
                    width:150px;
                    font-size:18px;
                    color: #bf0000;
                    font-weight: bold;
                }
            }
        }
    }
    .order-btn{
        padding:10px 0;
        text-align: right;
        margin-bottom: 20px;
        .paybtn{
            display: inline-block;
            padding:10px 20px;
            border-radius:4px;
            background: #222;
            color: #fff;
            
            cursor: pointer;
        }

    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shopcar{display:none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shopcar{display:none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        background: green;
        .wap-shopcar{display:none;}
        .pc-shopcar .pc-shopwrap .shop{width:50%;}
        .pc-shopcar .pc-shopwrap .pc-all{width:50%;padding:0px 0px 40px 20px;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shopcar{display:none;}
        .wap-shopcar{
            padding:0;
            .wap-shopinfo{
                background: #f4f7fe;
                padding:10px;
                .wap-title{
                    padding:10px 0;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    border-bottom:1px solid #dde0ed;
                    .wap-label{
                        display: flex;
                        width: 50%;
                        .icon_checkbox{
                            float: left;
                            display: block;
                            width:20px;
                            height:20px;
                            background:url(~@/assets/images/icon_check.png) no-repeat left top;
                            background-size: 100% 100%;
                            margin-top:3px;
                            margin-right:8px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                        .dischecked{
                            background:url(~@/assets/images/icon_duoxuan_lapse.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        .checked{
                            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                    }
                    .tit{
                        font-size:16px;
                    }
                    .rg{
                        color: #95959e;
                        justify-content: flex-end;
                    }
                }
                .wap-shop-group{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:10px 0;
                    .wap-shop-check{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding:0 10px;
                        .icon_checkbox{
                            float: left;
                            display: block;
                            width:20px;
                            height:20px;
                            background:url(~@/assets/images/icon_check.png) no-repeat left top;
                            background-size: 100% 100%;
                            margin-top:3px;
                            margin-right:8px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                        .dischecked{
                            background:url(~@/assets/images/icon_duoxuan_lapse.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        .checked{
                            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                    }
                    .wap-shop-img{
                        width:30%;
                        img{
                            width: 100%;
                        }
                    }
                    .wap-shop-info{
                        width:50%;
                        padding-left:20px;
                        .wap-shop-title{
                            
                            line-height: 30px;
                        }
                        .wap-shop-numer{
                            color: #a4a4ad;
                            
                        }
                        .wap-shop-money{
                            margin-top:20px;
                            color: #bf0000;
                        }
                    }
                    .wap-num{
                        width:20%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #a4a4ad;
                    }
                }
                .wap-calcul{
                    padding:20px 0px;
                    border-top: 1px solid #dde0ed;
                    border-bottom: 1px solid #dde0ed;
                    .wap-calcul-group{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding:5px 0;
                        .wap-calcul-txt{
                            width: 50%;
                            
                        }
                        .wap-calcul-num{
                            width: 50%;
                            text-align: right;
                            
                            .wap-allnum{
                                color: #9c9da5;
                                margin-left: 4px;
                                
                            }
                        }
                    }
                }
                
                .wap-brd{
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-calcul-group{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:15px 0px;
                    .wap-calcul-txt{
                        width: 50%;
                        
                    }
                    .wap-calcul-nums{
                        width: 50%;
                        text-align: right;
                        font-size:16px;
                    }
                    .wap-red{
                        width: 50%;
                        text-align: right;
                        font-size:16px;
                        color:#bf0000;
                    }
                }
            }
            .wap-shop-state{
                padding: 10px;
                margin: 0;
                .wap-title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-state-name{
                        
                        .wap-orderstate{
                            display: inline-block;
                            width: 22px;
                            height:22px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                    .wap-state-txt{
                        
                        vertical-align: middle;
                        .wap-title{
                            
                        }
                    }
                }
                .wap-pay-state{
                    width: 100%;
                    color: #616166;
                    
                    .wap-txt{
                        display: inline-block;
                        padding:10px 0 0 35px;
                        width: 100%;
                        color: #616166;
                        
                    }
                    .wap-label{
                        // display: inline-block;
                        width: 30%;
                        padding:5px 0;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-label_foot{
                        display: inline-block;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        cursor: pointer;
                        
                    }
                    .wap-ifos{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 0px;
                        
                    }
                    .wap-ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 8px;
                        
                    }
                    .wap-leg{
                        display: inline-block;
                        padding:5px 0px;
                        color: #2e2e2e;
                        
                        width: 70%;
                    }
                    
                }
                .wap-marg{
                    margin-top: 10px;
                }
                .wap-title{
                    font-size:18px;
                    padding-bottom: 10px;
                }
                .wap-select{
                    width: 100%;
                    height:58px;
                    margin-bottom: 20px;
                    padding:0 20px;
                    border-radius:5px;
                    border:1px solid #DDE0ED;
                    position: relative;
                    cursor: pointer;
                    .pc-select{
                        display: flex;
                        height: auto;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0 0px;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .pc-one_select{
                        height:58px;
                        line-height:58px;
                        width: 100%;
                        border: none;
                        outline: none;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .el-icon-arrow-down{
                        position: absolute;
                        right: 20px;
                        top:20px;
                    }
                    .pc-select-tit{
                        width: 100%;
                        height:28px;
                        line-height:28px;
                        
                        color: #95959E;
                    }
                    /deep/.el-select{
                        width: 100%;
                    }
                    .pc-select-namediy{
                        position: absolute;
                        left:0;
                        top:0;
                        height:58px;
                        line-height:58px;
                        width: 100%;
                        padding:0 20px;
                    }
                    .pc-select-diy{
                        position: absolute;
                        left:0;
                        top:56px;
                        z-index:1;
                        min-height:100px;
                        width: 100%;
                        border-radius:5px;
                        border:1px solid #DDE0ED;
                        width: 100%;
                        background: #fff;
                        .diylist{
                            padding:10px 20px;
                            background: #fff;
                            border-bottom:1px solid #DDE0ED;
                            &:last-child{
                            border-bottom:none; 
                            }
                        }
                    }
                }
                .wap-input_odd{
                    margin-bottom: 20px;
                    padding:5px 15px;
                    border-radius:4px;
                    border: 1px solid #dde0ed;
                    .wap-tit{
                        color: #9d9da5;
                    }
                    .wap-money{
                        color: #1f73b7
                    }
                }
                .wap-input{
                    /deep/.el-textarea__inner{
                        height:130px;
                        resize: none;
                        font-size:16px;
                        background: transparent;
                        border: 1px solid #dde0ed;
                    }
                }
                .wap-link{
                    height: 40px;
                    width: 100%;
                    text-align: center;
                    margin-top: 20px;
                    .wap-linkbtn{
                        display: inline-block;
                        padding:10px 20px;
                        width: 100%;
                        border-radius:4px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                }
            }
            .order-info-state{
                
                .title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .state-name{
                        font-size:18px;
                        .orderstate{
                            display: inline-block;
                            width: 26px;
                            height:26px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 20px;
                        }
                    }
                    .state-txt{
                        font-size:18px;
                        vertical-align: middle;
                        padding-top:3px;
                        .title{
                            font-size:18px;
                        }
                    }
                }
                .pay-state{
                    padding-left: 60px;
                    
                    color: #616166;
                    .label{
                        display: inline-block;
                        width: 80px;
                        
                        color: #616166;
                    }
                    .paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                    .ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 20px;
                        
                    }
                    .leg{
                        display: inline-block;
                        padding:5px 20px;
                        
                        color: #2e2e2e;
                    }

                }
                .marg{
                    margin-top: 40px;
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-shopcar{display:none;}
        .wap-shopcar{
            padding:0;
            .wap-shopinfo{
                background: #f4f7fe;
                padding:10px;
                .wap-title{
                    padding:10px 0;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    border-bottom:1px solid #dde0ed;
                    .wap-label{
                        display: flex;
                        width: 50%;
                        .icon_checkbox{
                            float: left;
                            display: block;
                            width:20px;
                            height:20px;
                            background:url(~@/assets/images/icon_check.png) no-repeat left top;
                            background-size: 100% 100%;
                            margin-top:3px;
                            margin-right:8px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                        .dischecked{
                            background:url(~@/assets/images/icon_duoxuan_lapse.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        .checked{
                            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                    }
                    .tit{
                        font-size:16px;
                    }
                    .rg{
                        color: #95959e;
                        justify-content: flex-end;
                    }
                }
                .wap-shop-group{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:10px 0;
                    .wap-shop-check{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding:0 10px;
                        .icon_checkbox{
                            float: left;
                            display: block;
                            width:20px;
                            height:20px;
                            background:url(~@/assets/images/icon_check.png) no-repeat left top;
                            background-size: 100% 100%;
                            margin-top:3px;
                            margin-right:8px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                        .dischecked{
                            background:url(~@/assets/images/icon_duoxuan_lapse.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        .checked{
                            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                    }
                    .wap-shop-img{
                        width:30%;
                        img{
                            width: 100%;
                        }
                    }
                    .wap-shop-info{
                        width:50%;
                        padding-left:20px;
                        .wap-shop-title{
                            
                            line-height: 30px;
                        }
                        .wap-shop-numer{
                            color: #a4a4ad;
                            
                        }
                        .wap-shop-money{
                            margin-top:20px;
                            color: #bf0000;
                        }
                    }
                    .wap-num{
                        width:20%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #a4a4ad;
                    }
                }
                .wap-calcul{
                    padding:20px 0px;
                    border-top: 1px solid #dde0ed;
                    border-bottom: 1px solid #dde0ed;
                    .wap-calcul-group{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding:5px 0;
                        .wap-calcul-txt{
                            width: 50%;
                            
                        }
                        .wap-calcul-num{
                            width: 50%;
                            text-align: right;
                            
                            .wap-allnum{
                                color: #9c9da5;
                                margin-left: 4px;
                                
                            }
                        }
                    }
                }
                
                .wap-brd{
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-calcul-group{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:15px 0px;
                    .wap-calcul-txt{
                        width: 50%;
                        
                    }
                    .wap-calcul-nums{
                        width: 50%;
                        text-align: right;
                        font-size:18px;
                    }
                    .wap-red{
                        width: 50%;
                        text-align: right;
                        font-size:18px;
                        color:#bf0000;
                    }
                }
            }
            .wap-shop-state{
                padding: 10px;
                margin: 0;
                .wap-title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-state-name{
                        
                        .wap-orderstate{
                            display: inline-block;
                            width: 22px;
                            height:22px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                    .wap-state-txt{
                        
                        vertical-align: middle;
                        .wap-title{
                            
                        }
                    }
                }
                .wap-pay-state{
                    width: 100%;
                    color: #616166;
                    
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-txt{
                        display: inline-block;
                        padding:10px 0 0 35px;
                        width: 100%;
                        color: #616166;
                        
                    }
                    .wap-label{
                        // display: inline-block;
                        width: 30%;
                        color: #616166;
                        
                        padding:5px 0px;
                        padding-left: 30px;
                    }
                    .wap-label_foot{
                        display: inline-block;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        cursor: pointer;
                        
                    }
                    .wap-ifos{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 0px;
                        
                    }
                    .wap-ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 8px;
                        
                    }
                    .wap-leg{
                        // display: inline-block;
                        padding:5px 0px;
                        color: #2e2e2e;
                        
                        width: 70%;
                    }
                    
                }
                .wap-marg{
                    margin-top: 10px;
                }
                .wap-title{
                    font-size:18px;
                    padding-bottom: 10px;
                }
                .wap-select{
                    width: 100%;
                    height:58px;
                    margin-bottom: 20px;
                    padding:0 20px;
                    border-radius:5px;
                    border:1px solid #DDE0ED;
                    position: relative;
                    cursor: pointer;
                    .pc-select{
                        display: flex;
                        height: auto;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0 0px;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .pc-one_select{
                        height:58px;
                        line-height:58px;
                        width: 100%;
                        border: none;
                        outline: none;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .el-icon-arrow-down{
                        position: absolute;
                        right: 20px;
                        top:20px;
                    }
                    .pc-select-tit{
                        width: 100%;
                        height:28px;
                        line-height:28px;
                        
                        color: #95959E;
                    }
                    /deep/.el-select{
                        width: 100%;
                    }
                    .pc-select-namediy{
                        position: absolute;
                        left:0;
                        top:0;
                        height:58px;
                        line-height:58px;
                        width: 100%;
                        padding:0 20px;
                    }
                    .pc-select-diy{
                        position: absolute;
                        left:0;
                        top:56px;
                        z-index:1;
                        min-height:100px;
                        width: 100%;
                        border-radius:5px;
                        border:1px solid #DDE0ED;
                        width: 100%;
                        background: #fff;
                        .diylist{
                            padding:10px 20px;
                            background: #fff;
                            border-bottom:1px solid #DDE0ED;
                            &:last-child{
                            border-bottom:none; 
                            }
                        }
                    }
                }
                .wap-input_odd{
                    margin-bottom: 20px;
                    padding:5px 15px;
                    border-radius:4px;
                    border: 1px solid #dde0ed;
                    .wap-tit{
                        color: #9d9da5;
                    }
                    .wap-money{
                        color: #1f73b7
                    }
                }
                .wap-input{
                    /deep/.el-textarea__inner{
                        height:130px;
                        resize: none;
                        font-size:16px;
                        background: transparent;
                        border: 1px solid #dde0ed;
                    }
                }
                .wap-link{
                    height: 40px;
                    width: 100%;
                    text-align: center;
                    margin-top: 20px;
                    .wap-linkbtn{
                        display: inline-block;
                        padding:10px 20px;
                        width: 100%;
                        border-radius:4px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                }
            }
            .order-info-state{
                
                .title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .state-name{
                        font-size:18px;
                        .orderstate{
                            display: inline-block;
                            width: 26px;
                            height:26px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 20px;
                        }
                    }
                    .state-txt{
                        font-size:18px;
                        vertical-align: middle;
                        padding-top:3px;
                        .title{
                            font-size:18px;
                        }
                    }
                }
                .pay-state{
                    padding-left: 60px;
                    
                    color: #616166;
                    .label{
                        display: inline-block;
                        width: 80px;
                        
                        color: #616166;
                    }
                    .paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                    .ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 20px;
                        
                    }
                    .leg{
                        display: inline-block;
                        padding:5px 20px;
                        
                        color: #2e2e2e;
                    }

                }
                .marg{
                    margin-top: 40px;
                }
            }
        }
    }
}
</style>